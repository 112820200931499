import React from 'react';
import "./App.css"

const Loader = () => {

  return (
    <>
        <div className='mainLoader'>
          <div className="lds-ripple">
            <div></div>
          </div>
        </div>  
    </>
  );
}

export default Loader;
