import React, { useEffect } from "react";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import $, { event } from "jquery";
import { useMemo } from "react";
import { useState } from "react";

import axios from "axios";
import { aviatorAPIKEY, aviatorBASEURL, baseURL, key } from "./config";
import { setToast } from "./toast";
import Loader from "./Loader";
axios.defaults.baseURL = baseURL;
const addresses = require("./fakecountries");
const user = require("./fakeusers");
const email = require("./fakeemails");

function getRandomAddress() {
  const randomIndex = Math.floor(Math.random() * addresses.length);
  return addresses[randomIndex];
}
function getRandomUser() {
  const randomIndex = Math.floor(Math.random() * user.length);
  return user[randomIndex];
}
function getRandomMail() {
  const randomIndex = Math.floor(Math.random() * email.length);
  return email[randomIndex];
}

const address = getRandomAddress();
const users = getRandomUser();
const mails = getRandomMail();

const VIPPlanStripPayment = (props) => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [address, setAddress] = useState({});
  const [error, setError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [name, setName] = useState("");
  const [cvcError, setCvcError] = useState("");
  const [cardError, setCardError] = useState("");
  const [expiryError, setExpiryError] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const params = new URLSearchParams(window.location.search);
  const userId = params.get("userId");
  const gameUserId = params.get("gameUserId");
  const gameAmount = params.get("gameAmount");
  const type = params.get("type");

  console.log("props.data.rupee", props.data.rupee);

  useEffect(() => {
    if (gameUserId) {
      fetch(`${aviatorBASEURL}user/getProfile?userId=${gameUserId}&key=${aviatorAPIKEY}`)
        .then(response => response.json())
        .then(data => {
          if (data?.status) {
            setUser(data?.user);
          } else {
            // history.push("/webPayment");
            setToast("error", data?.message);
          }
        })
        .catch(error => {
          console.error(error);
          setToast("error", error.message);
        });
    } else {
      axios
        .get(`admin/user/getProfileOfUser?userId=${userId}&key=${key}`)
        .then((response) => {
          if (response?.data?.status) {
            setUser(response?.data?.user);
          } else {
            // history.push("/webPayment");
            setToast("error", response?.data?.message);
          }
        })
        .catch((error) => {
          console.log(error);
          setToast("error", error.message);
        });
    }
  }, []);

  const useOptions = () => {
    const options = useMemo(
      () => ({
        style: {
          base: {
            width: "fit-content",
            borderRadius: "8px",
            background: "rgba(228, 228, 228, 0.3)",
            border: "2px solid transparent",
            fontSize: "17px",
            fontWeight: "600",
            color: "#000",
            transition: "all 0.25s",
            "::placeholder": {
              color: "#9c9797",
            },
          },
          invalid: {
            color: "#eb1c26",
          },
        },
      }),
      []
    );

    return options;
  };

  const options = useOptions();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setSubmitted(true);
    if (!stripe || !elements) {
      return;
    }
    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const addressElement = elements.getElement(AddressElement);
    if (
      !cardNumberElement ||
      !cardExpiryElement ||
      !cardCvcElement ||
      !addressElement
    ) {
      // Handle case where one or more elements are missing
      setError("One or more card elements are missing");
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        address,
        email: user?.email ? user.email : "",
        name: name,
        // phone: "9658253265",
      },
    });
    stripePaymentMethodHandler(result);
  };

  const stripePaymentMethodHandler = (result) => {
    if (gameUserId) {
      if (result.error) {
        elements.getElement(CardNumberElement).clear();
        elements.getElement(CardExpiryElement).clear();
        elements.getElement(CardCvcElement).clear();
        elements.getElement(AddressElement).clear();
      } else {
        let data = {
          currency: props.data?.currency,
          userId: props.data?.userId,
          amount: props.data.amount,
          payment_method_id: result.paymentMethod.id,

          billing_details: {
            address,
            email: user?.email ? user.email : "",
            name: name,
            // phone: "9658253265",
          },
        };

        fetch(`${aviatorBASEURL}payment/stripe`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'key': ` ${aviatorAPIKEY}`,
            // Add any other headers you need
          },
          body: JSON.stringify(data),
        })
          .then(response => {
            if (!response.ok) {
              return response.json().then(err => { throw err });
            }
            return response.json();
          })
          .then(json => {
            console.log("json data", json);
            setLoading(false);
            handleServerResponseGame(json);

            // window.showAndroidToast(json?.data?.status);
          })
          .catch(error => {
            console.error(error);
            setLoading(false);
            setToast("Decline", error?.message);
            window.parent.postMessage('paymentDeclined', '*');
            
            // window.paymentFaill(error?.message);
          });
        // });
      }
    } else {
      if (result.error) {
        elements.getElement(CardNumberElement).clear();
        elements.getElement(CardExpiryElement).clear();
        elements.getElement(CardCvcElement).clear();
        elements.getElement(AddressElement).clear();
      } else {
        let data = {
          planId: props.data?.planId,
          currency: props.data?.currency,
          userId: props.data?.userId,
          appId: props.data?.appId,
          amount: props.data.amount,
          payment_method_id: result.paymentMethod.id,

          billing_details: {
            address,
            email: user?.email ? user.email : "",
            name: name,
            // phone: "9658253265",
          },
        };

        axios.defaults.headers.common["key"] = key;
        axios
          .post(`admin/plan/payStripeWeb?type=${type}`, data)

          .then(function (json) {
            console.log("json data", json);
            setLoading(false);
            handleServerResponse(json);

            window.showAndroidToast(json?.data?.status);
          })
          .catch((error) => {
            console.log(error);
            setToast("Decline", error?.data?.message);
            window.patmentFaill(error?.data?.message)
          });
        // });
      }

    }
  };

  console.log("props.data", props.data)
  function handleServerResponse(response) {
    setLoading(true);

    if (response.error) {
      setToast("error", response.error.message);
      setLoading(false);
    } else if (response.data.requires_action) {
      stripe
        .confirmCardPayment(response.data.payment_intent_client_secret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              address,
              email: user?.email ? user.email : "",
              name: name,
              // phone: "9658253265",
            },
          },
          setup_future_usage: "off_session",
        })

        .then(function (res) {
          if (res) {
            setToast("success", "Payment Done Successfully!");
          } else {
            setToast("decline", "Payment Declined!");
          }

        });
    } else if (!response.data.status) {
      // Show success message
      setToast("error", response.data.message);

      elements.getElement(CardNumberElement).clear();
      elements.getElement(CardExpiryElement).clear();
      elements.getElement(CardCvcElement).clear();
      elements.getElement(AddressElement).clear();
    } else {
      // Show success message
      setToast("success", "added to your wallet!");

      elements.getElement(CardNumberElement).clear();
      elements.getElement(CardExpiryElement).clear();
      elements.getElement(CardCvcElement).clear();
      elements.getElement(AddressElement).clear();
      setLoading(false);
    }
  }
//   function handleServerResponseGame(response) {
//     setLoading(true);
// console.log("response",response)
//     if (response?.status === false) {
//       setToast("error", response?.message);
//       setLoading(false);
//     } else if (response.requires_action) {
//       stripe
//         .confirmCardPayment(response.payment_intent_client_secret, {
//           payment_method: {
//             card: elements.getElement(CardNumberElement),
//             billing_details: {
//               address,
//               email: user?.email ? user.email : "",
//               name: name,
//               // phone: "9658253265",
//             },
//           },
//           setup_future_usage: "off_session",
//         })

//         .then(function (res) {
//           if (res) {
//             setToast("success", "Payment Done Successfully!");
//             window.parent.postMessage('paymentSuccess', '*');
//           } else {
//             setToast("decline", "Payment Declined!");
//             window.parent.postMessage('paymentSuccess', '*');
//           }

//         });
//     } else if (!response.status) {
//       // Show success message
//       setToast("error", response.message);

//       elements.getElement(CardNumberElement).clear();
//       elements.getElement(CardExpiryElement).clear();
//       elements.getElement(CardCvcElement).clear();
//       elements.getElement(AddressElement).clear();
//     } else {
//       // Show success message
//       setToast("success", "added to your wallet!");
//       window.parent.postMessage('paymentSuccess', '*');
//       elements.getElement(CardNumberElement).clear();
//       elements.getElement(CardExpiryElement).clear();
//       elements.getElement(CardCvcElement).clear();
//       elements.getElement(AddressElement).clear();
//       setLoading(false);
//     }
//   }
function handleServerResponseGame(response) {
  setLoading(true);
  console.log("response", response)
  if (response?.status === false) {
    setToast("error", response?.message);
    window.parent.postMessage('paymentDeclined', '*');
    setLoading(false);
  } else if (response.requires_action) {
    stripe
      .confirmCardPayment(response.payment_intent_client_secret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            address,
            email: user?.email ? user.email : "",
            name: name,
            // phone: "9658253265",
          },
        },
        setup_future_usage: "off_session",
      })

      .then(function (res) {
        if (res) {
          setToast("success", "Payment Done Successfully!");
          window.parent.postMessage('paymentSuccess', '*');
        } else {
          setToast("decline", "Payment Declined!");
          window.parent.postMessage('paymentDeclined', '*');
        }

      });
  } else if (!response.status) {
    // Show success message
    setToast("error", response.message);

    elements.getElement(CardNumberElement).clear();
    elements.getElement(CardExpiryElement).clear();
    elements.getElement(CardCvcElement).clear();
    elements.getElement(AddressElement).clear();
  } else {
    // Show success message
    setToast("success", "added to your wallet!");

    elements.getElement(CardNumberElement).clear();
    elements.getElement(CardExpiryElement).clear();
    elements.getElement(CardCvcElement).clear();
    elements.getElement(AddressElement).clear();
    setLoading(false);
    window.parent.postMessage('paymentSuccess', '*');
  }
}
  $("input[type=number]").on("mousewheel", function (e) {
    $(e.target).blur();
  });

  // function handleStripeJsResult(result) {
  //   ;
  //   if (result.error) {
  //     ;
  //     setToast("error", result.error.message);
  //   } else {
  //     ;
  //     console.log("result", result);
  //     let data = {
  //       planId: props.data?.planId,
  //       currency: props.data?.currency,
  //       userId: props.data?.userId,
  //       appId: props.data?.appId,
  //       payment_method_id: result.paymentIntent?.client_secret,
  //       billing_details: {
  //         address,
  //         email: user?.email ? user.email : "",
  //         name: name,
  //         // phone: "9658253265",
  //       },
  //     };
  //     axios.defaults.headers.common["key"] = key;

  //     axios
  //       .post(`admin/plan/payStripeWeb?type=${type}`, data)
  //       .then(function (json) {
  //         ;
  //         console.log("second time json", json);
  //         handleServerResponse(json);
  //         setToast("Success", json.data.message);
  //         setLoading(false);
  //       })
  //       .catch((error) => {
  //         ;
  //         console.log(error);
  //         setToast("Decline", error.message);
  //         setLoading(false);
  //       });
  //     setLoading(false);
  //     // });
  //   }
  // }

  const currency = params.get("currency");
  const inputStyle = {
    showIcon: true,
    iconStyle: "solid",
    style: {
      borderRadius: "9px 9px 0px 0px",
    },
  };

  return (
    <>
      <div>
        {/* <Elements stripe={stripePromise}> */}
        {loading === true && <Loader />}
        <div className="row">
          <div className="col-12">
            <div class="PaymentHeader text-center">
              <div
                class="fw-bold"
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                Pay with card
              </div>
              <div className="col-12 px-0 my-2">
                <label
                  htmlFor="Amount"
                  style={{
                    fontSize: "16px",
                    color: "#1a1a1a99",
                    fontWeight: "600",
                  }}
                >
                  Amount
                </label>
                <div
                  className="form-input mt-2"
                  id="Amount"
                  style={{
                    padding: "8px ",
                    backgroundColor: "#f8f7fb",
                    border: "1px solid #00000042",
                    borderRadius: "10px",
                    fontSize: "25px",
                    fontWeight: "bold",
                  }}
                >
                  <span>
                    {`${currency === "inr"
                      ? props?.data?.amount + " " + "₹"
                      : props?.data?.amount + " " + "$"
                        ? props?.data?.amount + " " + "$"
                        : "Pay"
                      }`}
                  </span>
                </div>
              </div>
            </div>
            <label
              className="stripeLabel fw-bold mt-3  "
              style={{ fontSize: "14px", fontWeight: "bold" }}
            >
              Card information
            </label>
            <CardNumberElement
              className="mt-2"
              options={inputStyle}
              onChange={(event) => {
                if (event.error) {
                  setToast("error", event.error.message);
                  setAddressError(event.error.message);
                } else {
                  setAddressError("");
                }
                console.log(
                  "Card number changed:",
                  event.complete,
                  event.message
                );
              }}
            />
            <p className="text-danger text-center">{cardError && cardError}</p>
          </div>
          <div className="col-6 pe-0">
            <CardExpiryElement
              className=""
              onChange={(event) => {
                if (event.error) {
                  setToast("error", event.error.message);
                  setExpiryError(event.error.message);
                } else {
                  setExpiryError("");
                }
                console.log(
                  "Card number changed:",
                  event.complete,
                  event.error
                );
              }}
            />
            <p className="text-danger text-center">
              {expiryError && expiryError}
            </p>
          </div>
          <div className="col-6 ps-0">
            <CardCvcElement
              className=""
              options={inputStyle}
              onChange={(event) => {
                if (event.error) {
                  setToast("error", event.error.message);
                  return setCvcError(event.error.message);
                } else {
                  setCvcError("");
                }
                console.log(
                  "Card number changed:",
                  event.complete,
                  event.error
                );
              }}
            />
            <p className="text-danger text-center">{cvcError && cvcError}</p>
          </div>
          <div className="col-12" style={{ marginTop: "10px" }}>
            <label
              className=""
              style={{ fontSize: "14px", fontWeight: "bold" }}
            >
              Billing Address
            </label>
            <AddressElement
              options={{ mode: "billing" }}
              className="mt-2"
              onChange={(event) => {
                if (event.error) {
                  setToast("error", event.error.message);
                  setAddressError(event.error.message);
                } else {
                  setAddressError("");
                }
                const isComplete = Object.values(event.value).every(
                  (field) => !!field
                );

                if (event.complete) {
                  setAddress(event.value.address);
                  setName(event.value.name);
                }
              }}
            />
            <p className="text-danger text-center">
              {addressError && addressError}
            </p>
          </div>

          <button
            className="btn btn-primary d-flex justify-content-center w-100 mt-4 stripe_pay_button mx-2"
            style={{ fontWeight: "bold" }}
            type="submit"
            disabled={!elements || !stripe}
            onClick={handleSubmit}
          >
            Pay
          </button>
        </div>
        {/* </Elements> */}
      </div>
    </>
  );
};

export default VIPPlanStripPayment;
