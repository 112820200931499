const email = [
  {
    _id: {
      $oid: "64b7d93ad52177fbb2ab3ded",
    },
    email: "oppimahajan@gmail.com",
  },
  {
    _id: {
      $oid: "64b7d93ad52177fbb2ab3dee",
    },
    email: "asilova708@gmail.com",
  },
  {
    _id: {
      $oid: "64b7d93ad52177fbb2ab3def",
    },
    email: "kartikabinti@gmail.com",
  },
  {
    _id: {
      $oid: "64b7d93ad52177fbb2ab3df0",
    },
    email: "irafla493@gmail.com",
  },
  {
    _id: {
      $oid: "64b7d93ad52177fbb2ab3df1",
    },
    email: "jahanyark@gmail.com",
  },
  {
    _id: {
      $oid: "64b7d93ad52177fbb2ab3df2",
    },
    email: "ninukilo082@gmail.com",
  },
  {
    _id: {
      $oid: "64b7d93ad52177fbb2ab3df3",
    },
    email: "jakiajannat1999@gmail.com",
  },
  {
    _id: {
      $oid: "64b7d93ad52177fbb2ab3df4",
    },
    email: "mdsarifkhan789@gmail.com",
  },
  {
    _id: {
      $oid: "64b7d93ad52177fbb2ab3df5",
    },
    email: "mdmosharofhosen1990@gmail.com",
  },
  {
    _id: {
      $oid: "64b7d93ad52177fbb2ab3df6",
    },
    email: "babulhosen4187@gmail.com",
  },
  {
    _id: {
      $oid: "64b7d93ad52177fbb2ab3df7",
    },
    email: "umakantoroy8@gmail.com",
  },
  {
    _id: {
      $oid: "64b7d93ad52177fbb2ab3df8",
    },
    email: "amitshahh1992@gmail.com",
  },
  {
    _id: {
      $oid: "64b7d93ad52177fbb2ab3df9",
    },
    email: "ceceliabeverly9@gmail.com",
  },
  {
    _id: {
      $oid: "64b7d93ad52177fbb2ab3dfa",
    },
    email: "razenstudebts@gmail.com",
  },
  {
    _id: {
      $oid: "64b7d93ad52177fbb2ab3dfb",
    },
    email: "jonymd34629@gmail.com",
  },
  {
    _id: {
      $oid: "64b7d93ad52177fbb2ab3dfc",
    },
    email: "ahmedshuhan677@gmail.com",
  },
  {
    _id: {
      $oid: "64b7d93ad52177fbb2ab3dfd",
    },
    email: "shathiaktar9452@gmail.com",
  },
];

module.exports = email;