const address = [
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3dd9",
    },
    line1: "303 Collins Rd NE",
    postal_code: "52402",
    city: "Cedar Rapids",
    state: "Idaho",
    country: "US",
  },
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3dda",
    },
    line1: "111 East Braodway #190",
    postal_code: "84111",
    city: "Salt Lake City",
    state: "Utah",
    country: "US",
  },
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3ddb",
    },
    line1: "3259 Palm Pkwy",
    postal_code: "89104",
    city: "Las Vegas",
    state: "North Carolina",
    country: "US",
  },
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3ddc",
    },
    line1: "5651 Pebble Village Ln",
    postal_code: "46062",
    city: "Noblesville",
    state: "Iowa",
    country: "US",
  },
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3ddd",
    },
    line1: "1706 W Till Rd",
    postal_code: "46818",
    city: "Fort Wayne",
    state: "Iowa",
    country: "US",
  },
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3dde",
    },
    line1: "525 Broadway St",
    postal_code: "54619",
    city: "Cashton",
    state: "West Virginia",
    country: "US",
  },
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3ddf",
    },
    line1: "2260 Main St",
    postal_code: "91911",
    city: "Chula Vista",
    state: "California",
    country: "US",
  },
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3de0",
    },
    line1: "5611 266th St",
    postal_code: "55092",
    city: "Wyoming",
    state: "Minnesota",
    country: "US",
  },
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3de1",
    },
    line1: "200 Consilium Pl #104",
    postal_code: "M1H 3E4",
    city: "Scarborough",
    state: "Ontario",
    country: "CA",
  },
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3de2",
    },
    line1: "450 Erb St W #4",
    postal_code: "N2T 1H4",
    city: "Waterloo",
    state: "Ontario",
    country: "CA",
  },
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3de3",
    },
    line1: "3957 Lakeshore Rd",
    postal_code: "V1W 1V5",
    city: "Kelowna",
    state: "British Columbia",
    country: "CA",
  },
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3de4",
    },
    line1: "266 Duvernay",
    postal_code: "J3G 2M2",
    city: "Beloeil",
    state: "Quebec",
    country: "CA",
  },
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3de5",
    },
    line1: "1663 Rue Amherst",
    postal_code: "H2L 3L4",
    city: "Montréal",
    state: "Quebec",
    country: "CA",
  },
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3de6",
    },
    line1: "1919 Bishop Grandin Blvd",
    postal_code: "R2M 3E8",
    city: "Winnipeg",
    state: "Manitoba",
    country: "CA",
  },
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3de7",
    },
    line1: "6085 Creditview Rd #2d",
    postal_code: "L5V 2A8",
    city: "Mississauga",
    state: "Ontario",
    country: "CA",
  },
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3de8",
    },
    line1: "6610 176 St",
    postal_code: "V3S 4G5",
    city: "Surrey",
    state: "British Columbia",
    country: "CA",
  },
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3de9",
    },
    line1: "2025 Union Ave",
    postal_code: "H3A 0A3",
    city: "Montreal",
    state: "Quebec",
    country: "CA",
  },
  {
    _id: {
      $oid: "64b7d09cd52177fbb2ab3dea",
    },
    line1: "775 9th Ave E",
    postal_code: "N4K 3E6",
    city: "Owen Sound",
    state: "Ontario",
    country: "CA",
  },
];
 module.exports = address;