// import React from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import { useHistory, useLocation } from "react-router-dom";
// import { connect, useSelector } from "react-redux";
import VIPPlanStripPayment from "./VIPPlanStripPayment";
import { useEffect } from "react";
// import { setting } from "../../store/Setting/setting.action";
import React, { useState } from "react";
import axios from "axios";
import { baseURL, key } from "./config";
import logo from "./logo.png";
import { useParams } from "react-router-dom";
import Loader from "./Loader";
import { setToast } from "./toast";
// import { getPlan } from "../../store/webPayment/webPayment.action"
axios.defaults.baseURL = baseURL;
const WebPayment = (props) => {
  const [keyValue, setKeyValue] = useState("");
  const [planValue, setPlanValue] = useState("");
  const [data, setData] = useState("");
  // const location = useLocation();
  // const history = useHistory();
  const params = new URLSearchParams(window.location.search);

  console.log("params", params);

  const appId = params.get("appId");
  const planId = params.get("planId");
  const userId = params.get("userId");
  const gameUserId = params.get("gameUserId");
  const gameAmount = params.get("gameAmount");
  const currency = params.get("currency");
  const type = params.get("type");
  const amount = params.get("amount");
  let planAmount;

  console.log("datadatadatadata", data);
  console.log("planAmount", planAmount);

  // console.log('getData', getData)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (gameUserId) {
      setData({
        userId: gameUserId,
        currency: "inr",
        planId: "",
        appId: "",
        amount: gameAmount
      });

      setKeyValue({
        stripePublishableKey: "pk_test_51MrNpNGN45HJoheDzNEiN5BgqT0uowsawXa5WEbPDTj4bGgX5SsYdRifDkjvyESJ82JXYot6BXsoFHQ7CJxLCB9e00895DfoTS"
      })
    } else {
      setLoading(true);
      axios.defaults.headers.common["key"] = key;
      axios
        .get(`admin/setting/getSetting?appId=${appId}`)
        .then((res) => {
          setKeyValue(res.data.setting);
          console.log("res.data.setting", res.data.setting);
          setLoading(false);
        })
        .catch((error) => console.log(error));
      axios
        .get(
          `admin/plan/getParticularPlan?planId=${planId}&appId=${appId}&type=${type}`,
          amount
        )
        .then((res) => {

          setData({
            ...res.data.plan,
            userId: userId,
            currency: currency,
            planId: planId,
            appId: appId,
            amount:
              type === "vipPlan" || type === "coinPlan"
                ? (type === "vipPlan" && currency === "usd"
                  ? res.data?.plan?.dollar
                  : 0) ||
                (type === "vipPlan" && currency === "inr"
                  ? res.data?.plan?.rupee
                  : 0) ||
                (type === "coinPlan" && currency === "usd"
                  ? res.data?.plan?.dollar
                  : 0) ||
                (type === "coinPlan" && currency === "inr"
                  ? res.data?.plan?.rupee
                  : 0)
                : amount,
          });
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, []);

  const PUBLIC_KEY = keyValue?.stripePublishableKey;
  // const PUBLIC_KEY = "pk_test_51NYnxSSEKuUvNQdWdZFymY2lKY4pmWfMJh8pTzBsD9ZOUHHOdvxMxTIGSYn6mt2NY9gFb0mLNyhzDPIpuygFRkFX003aDvrAtQ";

  console.log("PUBLIC_KEY", PUBLIC_KEY);

  const stripeTestPromise = loadStripe(PUBLIC_KEY);

  return (
    <>
      {loading === true && <Loader />}

      <div
        className="modal-body pt-1 px-1 "
        style={{
          background:
            "linear-gradient(164deg, rgba(228,238,255,1) 0%, rgba(235,229,255,1) 100%)",
          // background:"#e4eeff",
          height: "100vh",
        }}
      >
        {loading === false && (
          <div>
            {/* <div className="d-flex justify-content-end">
         <button
           className="btn btn-primary btn-icon px-4 me-4 m-3"
           onClick={goPrevious}
         >
           <i class="fa-solid fa-angles-left text-white fs-6"></i>
         </button>
       </div> */}
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xxl-6 ">
                {/* <div className="text-center ">
             <h1 className="mb-0"> Web Payment Stripe</h1>
           </div> */}
                <div className="d-flex justify-content-center stripe_card_height">
                  <div
                    classNMae="card"
                    style={{
                      width: "470px",
                      margin: "auto",
                    }}
                  >
                    <div className="card-body">
                      <div className="row">
                        <div className="">
                          <Elements stripe={stripeTestPromise}>
                            <VIPPlanStripPayment data={data} />
                          </Elements>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

// export default connect(null, { setting, getPlan })(WebPayment);
export default WebPayment;
