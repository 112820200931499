const user = [
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dbd",
    },
    name: "Johnny Richmond",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dbe",
    },
    name: "Jaron Hicks",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dbf",
    },
    name: "Jayda Ferguson",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dc0",
    },
    name: "Hugo Ellis",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dc1",
    },
    name: "Marlie Holmes",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dc2",
    },
    name: "Sawyer Saunders",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dc3",
    },
    name: "Itzel Ho",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dc4",
    },
    name: "Marisol Walton",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dc5",
    },
    name: "Fabian Eaton",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dc6",
    },
    name: "Akira Hart",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dc7",
    },
    name: "Rey Mcdaniel",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dc8",
    },
    name: "Kira Page",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dc9",
    },
    name: "Jeffery Villanueva",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dca",
    },
    name: "Leanna Huynh",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dcb",
    },
    name: "Coleman Morris",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dcc",
    },
    name: "Niko Patrick",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dcd",
    },
    name: "Matias Mathis",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dce",
    },
    name: "Rubi Guzman",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dcf",
    },
    name: "Milagros Carlson",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dd0",
    },
    name: "April Jefferson",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dd1",
    },
    name: "Lance Tapia",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dd2",
    },
    name: "Alivia Moses",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dd3",
    },
    name: "Aliya Black",
  },
  {
    _id: {
      $oid: "64b7c5e5d52177fbb2ab3dd4",
    },
    name: "Benjamin Cantu",
  },
];

module.exports = user;