import logo from './logo.svg';
import './App.css';
import WebPayment from './WebPayment';

import { useState } from 'react';

function App() {
  return (
    <div className="App">

    <WebPayment/>


    </div>
  );
}

export default App;
